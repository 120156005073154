import { Column1, GridWithGap } from '../../components/styled/grid'
import { Section } from '../../components/styled/section'
import Img from 'gatsby-image'
import React from 'react'
const EuKontrollSection = ({ affiliationName, slug, image }) => {
  return (
    <Section>
      <GridWithGap>
        <Column1>
          <Img fluid={image} />
        </Column1>
      </GridWithGap>
    </Section>
  )
}

export default EuKontrollSection
