import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { Section } from '../../components/styled/section'
import EuKontrollSection from '../../templates/sections/eu-kontroll'
import { Column1, Column2, GridWithGap } from '../styled/grid'
const HedingElement = styled.h3`
  border-bottom: 2px solid #dddddd;
  font-weight: 700;
  color: #1a51a0;
`

const TexBox = styled.div`
  //max-width: 1000px;
  margin: 5px auto;
  margin-top: -2rem;
  margin-bottom: -5rem;
`
const TextBoks = () => {
  const data = useStaticQuery(graphql`
    query {
      eu: file(relativePath: { eq: "eu-kontroll.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div style={{ marginBottom: '5rem' }}>
      <Section>
        <TexBox>
          <GridWithGap>
            <Column2>
              <HedingElement>Hva er en EU-kontroll</HedingElement>
              Våre verksteder er godkjent av myndighetene for Periodisk kjøretøykontroll (PKK)
              EU-kontroll. Dette er en lovpålagt kontroll alle bileiere må gjennomføre.
              <p>
                Bilen skal på EU-kontroll når den er 4 år gammel første gang, deretter hvert annet
                år. Bilen skal være ferdig godkjent før datoen utløper.
              </p>
              <p>
                Alle våre verksteder utfører også etterkontroll av EU-kontroll og eventuell
                godkjenning av bilen.
              </p>
              <a href="/service-og-reparasjon/EU-kontroll" className="link">
                Les mer om EU-kontroll
              </a>
            </Column2>
            <Column1>
              <EuKontrollSection image={data.eu.childImageSharp.fluid} />
            </Column1>
          </GridWithGap>
        </TexBox>
      </Section>
    </div>
  )
}

export default TextBoks
