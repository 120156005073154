import React, { useState } from 'react'
import { navigate } from 'gatsby'
import SEO from '../../components/seo'
import SubPageLayoutNoHero from '../../layouts/subPageLayoutNoHero'
import NextPkkHeroOption from '../../components/nextPkkHeroOption'
import styled from 'styled-components'
import { Section } from '../../components/styled/section'
import TextBoks from '../../components/small_web_comp/TextBoks'
import SelectWorkshopModal from '../../components/selectWorkshopModal'
import { generateWorkshopPagePrefix } from '../../url-helpers'
import { device } from '../../components/device'
import { Layout } from '../../components/styled/layout'
import SubPageVideoHero from '../../components/subPageVideoHero'
const PageSection = styled(Section)`
  display: block;
  align-items: center;
  margin: 5px auto;
`
const Text = styled.div`
  margin-top: 5rem;
  a {
    display: none;
  }
  @media ${device.tabletMaxL} {
    margin-top: 0;
  }

  @media (max-width: 1447px) {
    margin-top: 5rem;
  }
  @media (max-width: 772px) {
    margin-top: 0rem;
  }
`

const HolderBox = styled.div`
  margin-top: -3rem;
  margin-bottom: -3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 25px;

  @media ${device.tabletMaxL} {
    position: relative;
    top: 5%;
    left: 5%;
    padding-bottom: 5rem;
    margin-top: -1rem;
    transform: translate(-5%, -10%);
  }

  @media (max-width: 1441px) {
    margin-top: 0rem;
  }
`

const EUkontroll = () => {
  const [showWorkshopSelector, setShowWorkshopSelector] = useState(false)
  const Video = require('../../images/video/EU-kontroll.mp4')
  const title = 'EU-Kontroll - Periodisk kjøretøykontroll'
  return (
    <Layout>
      {showWorkshopSelector && (
        <SelectWorkshopModal
          onClose={() => setShowWorkshopSelector(false)}
          onSelected={async (workshop) => {
            const prefix = generateWorkshopPagePrefix(workshop)
            await navigate(`/${prefix}/${workshop.slug}#Bestilltime`)
          }}
        />
      )}

      <SubPageLayoutNoHero title={title}>
        <SEO title={title} />
        <SubPageVideoHero title={title} Video={Video}></SubPageVideoHero>
        <PageSection>
          <Text>
            <TextBoks />
          </Text>
          <PageSection>
            <HolderBox>
              <NextPkkHeroOption
                orderButtonClicked={() => {
                  setShowWorkshopSelector(true)
                }}
              />
            </HolderBox>
          </PageSection>
        </PageSection>
      </SubPageLayoutNoHero>
    </Layout>
  )
}
export default EUkontroll
